import React, { useState, useRef, useEffect } from 'react';
import Slider, { Settings } from 'react-slick';
import Img from 'gatsby-image/withIEPolyfill';

import { Heading } from '../../Heading/Heading';
import { cnVarietyPromo } from '../VarietyPromo';
import { SliderButton } from '../../SliderButton/SliderButton';

import './VarietyPromo-Slider.scss';
import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby'

export function VarietyPromoSlider() {
    const slides = ['TANKS', 'AMBUSHERS', 'SNIPERS', 'SCOUTS', 'TROOPERS'];
    const lastSlideIndex = slides.length - 1;
    const firstSlideIndex = 0;

    const [currentSlide, setSlide] = useState(firstSlideIndex);

    const mirrorSliderHandle = (n: number) => () => {
        setSlide(n);
    };
    const incSlide = () => setSlide(currentSlide === lastSlideIndex ? 0 : currentSlide + 1);
    const decSlide = () => setSlide(currentSlide === firstSlideIndex ? lastSlideIndex : currentSlide - 1);

    const sliderRef: React.Ref<Slider> = useRef(null);

    useEffect(() => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(currentSlide);
        }
    });

    const settings: Settings = {
        arrows: false,
        infinite: true,
        fade: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        useTransform: true,
        afterChange: setSlide,
    };

    const images = useStaticQuery(graphql`
        query {
            tanks: file(relativePath: { eq: "variety-carousel-tanks.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1365) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            ambushers: file(relativePath: { eq: "variety-carousel-ambushers.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1365) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            snipers: file(relativePath: { eq: "variety-carousel-snipers.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1365) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            scouts: file(relativePath: { eq: "variety-carousel-scouts.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1365) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            troopers: file(relativePath: { eq: "variety-carousel-troopers.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1365) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <div className={cnVarietyPromo('SliderSlick')}>
            <Slider {...settings} ref={sliderRef}>
                <div className={cnVarietyPromo('SliderItem')}>
                    {/* <img src={Tanks} alt="tanks" className={cnVarietyPromo('SliderImage')}/> */}
                    <Img
                        fluid={images.tanks.childImageSharp.fluid}
                        className={cnVarietyPromo('SliderImage')}
                        alt="tanks"
                        objectPosition="left top"
                        />
                    <Heading level="1" className={cnVarietyPromo('SliderHeading')}>
                        tanks
                    </Heading>
                </div>
                <div className={cnVarietyPromo('SliderItem')}>
                    {/* <img src={Ambushers} alt="ambushers" className={cnVarietyPromo('SliderImage')}/> */}
                    <Img
                        fluid={images.ambushers.childImageSharp.fluid}
                        className={cnVarietyPromo('SliderImage')}
                        alt="ambushers"
                        objectPosition="left top"
                        />
                    <Heading level="1" className={cnVarietyPromo('SliderHeading')}>
                        ambushers
                    </Heading>
                </div>
                <div className={cnVarietyPromo('SliderItem')}>
                    {/* <img src={Snipers} alt="snipers" className={cnVarietyPromo('SliderImage')}/> */}
                    <Img
                        fluid={images.snipers.childImageSharp.fluid}
                        className={cnVarietyPromo('SliderImage')}
                        alt="snipers"
                        objectPosition="left top"
                        />
                    <Heading level="1" className={cnVarietyPromo('SliderHeading')}>
                        snipers
                    </Heading>
                </div>
                <div className={cnVarietyPromo('SliderItem')}>
                    {/* <img src={Scouts} alt="scouts" className={cnVarietyPromo('SliderImage')}/> */}
                    <Img
                        fluid={images.scouts.childImageSharp.fluid}
                        className={cnVarietyPromo('SliderImage')}
                        alt="scouts"
                        objectPosition="left top"
                        />
                    <Heading level="1" className={cnVarietyPromo('SliderHeading')}>
                        scouts
                    </Heading>
                </div>
                <div className={cnVarietyPromo('SliderItem')}>
                    {/* <img src={Troopers} alt="troopers" className={cnVarietyPromo('SliderImage')}/> */}
                    <Img
                        fluid={images.troopers.childImageSharp.fluid}
                        className={cnVarietyPromo('SliderImage')}
                        alt="troopers"
                        objectPosition="left top"
                        />
                    <Heading level="1" className={cnVarietyPromo('SliderHeading')}>
                        troopers
                    </Heading>
                </div>
            </Slider>

            <div className={cnVarietyPromo('MirrorSlider')}>
                <SliderButton arrow="left" adapt onClick={decSlide} />
                {slides.map((type, i) => {
                    return (
                        <div
                            className={cnVarietyPromo('MirrorSliderItem', { type, active: i === currentSlide })}
                            onClick={mirrorSliderHandle(i)}
                            key={`mirror-slider-item-${type}`}
                        >
                            {type}
                        </div>
                    );
                })}
                <SliderButton arrow="right" adapt onClick={incSlide} />
            </div>
        </div>
    );
}
