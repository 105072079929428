import * as React from 'react';
import { cn } from '@bem-react/classname';

import './DownloadBadge.scss';
import { Links } from '../../config';

interface IDownloadBadgeProps {
    type: 'googleplay' | 'appstore';
    size?: 's' | 'l';
    className?: string;
    adapt?: boolean;
}

const cnDownloadBadge = cn('DownloadBadge');

export const DownloadBadge: React.FunctionComponent<IDownloadBadgeProps> = ({
    className,
    type,
    size = 'l',
    adapt,
}) => {
    let href = Links.playMarket;

    if (type === 'appstore') {
        href = Links.appStore;
    }

    return (
        <a href={href} className={cnDownloadBadge({ type, size, adapt }, [className])}>&nbsp;</a>
    );
};
