import React, { ReactChild } from 'react';
import { cn } from '@bem-react/classname';

import './Button.scss';

interface IButtonProps {
    href?: string;
    target?: string;
    className?: string;
    view: 'blue' | 'green';
    size: 's' | 'm' | 'l';
    children?: ReactChild | ReactChild[];
    adapt?: boolean;
}

const buttonCn = cn('Button');

export function Button({ target, href, className, view, size = 's', children, adapt }: IButtonProps) {
    if (!href) {
        return (
            <div className={buttonCn({ view, size, adapt }, [className])}>{children}</div>
        );
    }

    return (
        <a className={buttonCn({ view, size, adapt }, [className])} href={href} target={target}>
            {children}
        </a>
    );
}
