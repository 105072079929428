import * as React from 'react';
import { cn } from '@bem-react/classname';
import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image';


import { Heading } from '../Heading/Heading';
import { Button } from '../Button/Button';
import { Links, ScreenConstants, media } from '../../config';


import './SubscribePromo.scss';

const cnSubscribePromo = cn('SubscribePromo');

export function SubscribePromo() {

    // No way to use constants in query
    // @see README.md "Known issues"
    const images = useStaticQuery(graphql`
        query {
            desktop: file(relativePath: { eq: "SubscribePromo-bg.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            desktopRetina: file(relativePath: { eq: "SubscribePromo-bg@2x.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 3840) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            xs: file(relativePath: { eq: "SubscribePromo-bg.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 480) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            xsRetina: file(relativePath: { eq: "SubscribePromo-bg@2x.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    const bg = [
        images.desktop.childImageSharp.fluid,
        {
            ...images.desktopRetina.childImageSharp.fluid,
            media: media.retina,
        },
        {
            ...images.xs.childImageSharp.fluid,
            media: media.xs,
        },
        {
            ...images.xsRetina.childImageSharp.fluid,
            media: media.xsRetina,
        },
    ];

    return (
        <div className={cnSubscribePromo()}>
            <BackgroundImage className={cnSubscribePromo('Bg')} fluid={bg}>
                <div className={cnSubscribePromo('DiscordIcon')}></div>
                <Heading level="1" adapt>
                    JOIN OUR DISCORD CHANNEL
                </Heading>
                <Button href={Links.discord} view="blue" size="m" className={cnSubscribePromo('SubscribeButton')}>
                    <div className="ButtonText">SUBSCRIBE</div>
                    <div className={cnSubscribePromo('ArrowIcon')}></div>
                </Button>
                <div className={cnSubscribePromo('SideImage', { side: 'left' })}></div>
                <div className={cnSubscribePromo('SideImage', { side: 'right' })}></div>
            </BackgroundImage>
        </div>
    );
};
