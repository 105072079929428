import React from 'react';
import { socialLinksCn } from '../SocialLinks';

import './SocialLinks-PolicyLinks.scss';
import { Links } from '../../../config';

export function SocialLinksPolicyLinks() {
    return (
        <div className={socialLinksCn('PolicyLinkList')}>
            <a href={Links.terms} className={socialLinksCn('PolicyLink')}>Terms of Use</a>
            <a href={Links.privacy} className={socialLinksCn('PolicyLink')}>Privacy Policy</a>
        </div>
    );
};
