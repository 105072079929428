import React from 'react';
// import { Link } from "gatsby";

import Layout from '../components/Layout/Layout';
import { SEO } from '../components/Seo/Seo';
import { MainPromo } from '../components/MainPromo/MainPromo';
import { FeaturesPromo } from '../components/FeaturesPromo/FeaturesPromo';
import { VarietyPromo } from '../components/VarietyPromo/VarietyPromo';
import { ScreenshotsPromo } from '../components/ScreenshotsPromo/ScreenshotsPromo';
import { GameModesPromo } from '../components/GameModesPromo/GameModesPromo';
import { RewardsPromo } from '../components/RewardsPromo/RewardsPromo';
import { SubscribePromo } from '../components/SubscribePromo/SubscribePromo';
import { Footer } from '../components/Footer/Footer';
import { HeaderDownloadPromo } from '../components/HeaderDownloadPromo/HeaderDownloadPromo';

const IndexPage = () => (
    <Layout>
        <SEO />

        <HeaderDownloadPromo />
        <MainPromo />
        <FeaturesPromo />
        <VarietyPromo />
        <ScreenshotsPromo />
        <GameModesPromo />
        <RewardsPromo />
        <SubscribePromo />

        <Footer />
        {/* <Link to="/page-2/">Go to page 2</Link> */}
    </Layout>
);

export default IndexPage;
