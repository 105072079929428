import React, { useRef, useEffect } from 'react';
import { cn } from '@bem-react/classname';
import Slider, { Settings } from 'react-slick';
import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill';

import { ScreenConstants } from '../../config';

import { cnSliderDots } from '../SliderDots/SliderDots';
import { Heading } from '../Heading/Heading';
import { SliderButton } from '../SliderButton/SliderButton';

import './ScreenshotsPromo.scss';

const cnScreenshotsPromo = cn('ScreenshotsPromo');

export function ScreenshotsPromo() {
    const sliderRef: React.Ref<Slider> = useRef(null);

    const nextHandler = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const prevHandler = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const settings: Settings = {
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,

        // Does not work properly :( fixed via css
        centerMode: false,

        // https://github.com/akiran/react-slick/issues/648
        // N.B. property duplicated in scss because of bug in Galaxy S5
        // with initial open on SM screen size
        centerPadding: '60px',

        responsive: [
            {
                breakpoint: ScreenConstants.screenSM,
                settings: {
                    dots: true,
                    dotsClass: cnSliderDots(),
                    centerMode: true,
                },
            },
            {
                breakpoint: ScreenConstants.screenXS,
                settings: {
                    dots: true,
                    dotsClass: cnSliderDots(),
                    centerMode: true,
                    // N.B. property duplicated in scss because of bug in Galaxy S5
                    // with initial open on SM screen size
                    centerPadding: '0',
                }
            }
        ],
    };

    useEffect(() => {
        // Fix Xiaomi bug with wrong highlighted item. @see Xiaomi here:
        // https://docs.google.com/spreadsheets/d/1SQ1mszyGZYFE5C_aRPHhdrNSu39RniDpfpSk4t-UymU/edit#gid=1858673185
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(-1);
            sliderRef.current.forceUpdate();
        }
    })

    const images = useStaticQuery(graphql`
        query {
            image1: file(relativePath: { eq: "screenshot-carousel-1.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 2000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            image2: file(relativePath: { eq: "screenshot-carousel-2.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 2000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            image3: file(relativePath: { eq: "screenshot-carousel-3.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 2000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            image4: file(relativePath: { eq: "screenshot-carousel-4.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 2000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            image5: file(relativePath: { eq: "screenshot-carousel-5.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 2000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <div className={cnScreenshotsPromo()}>
            <Heading level="1" adapt className={cnScreenshotsPromo('Heading')}>
                SCREENSHOTS
            </Heading>

            <SliderButton
                arrow="left"
                className={cnScreenshotsPromo('SliderButton', { type: 'left' })}
                onClick={prevHandler}
                adapt
            />

            <div className={cnScreenshotsPromo('SliderWrapper')}>
                {/* TODO: IMAGES */}
                <Slider {...settings} ref={sliderRef}>
                    <div className={cnScreenshotsPromo('ImageWrapper')}>
                        <Img
                            fluid={images.image1.childImageSharp.fluid}
                            className={cnScreenshotsPromo('SliderImage')}
                            alt="Team up with friends"
                            />
                        {/* <img src={Item1} alt="First screenshot" /> */}
                    </div>
                    <div className={cnScreenshotsPromo('ImageWrapper')}>
                        {/* <img src={Item2} alt="Second screenshot" /> */}
                        <Img
                            fluid={images.image2.childImageSharp.fluid}
                            className={cnScreenshotsPromo('SliderImage')}
                            alt="Join fierce battles"/>
                    </div>
                    <div className={cnScreenshotsPromo('ImageWrapper')}>
                        {/* <img src={Item3} alt="Third screenshot" /> */}
                        <Img
                            fluid={images.image3.childImageSharp.fluid}
                            className={cnScreenshotsPromo('SliderImage')}
                            alt="Unlock new heroes"/>
                    </div>
                    <div className={cnScreenshotsPromo('ImageWrapper')}>
                        {/* <img src={Item4} alt="Fourth screenshot" /> */}
                        <Img
                            fluid={images.image4.childImageSharp.fluid}
                            className={cnScreenshotsPromo('SliderImage')}
                            alt="Try multiple game modes"
                            />
                    </div>
                    <div className={cnScreenshotsPromo('ImageWrapper')}>
                        {/* <img src={Item5} alt="Fifth screenshot" /> */}
                        <Img
                            fluid={images.image5.childImageSharp.fluid}
                            className={cnScreenshotsPromo('SliderImage')}
                            alt="Master the strategy"/>
                    </div>
                </Slider>
            </div>

            <SliderButton
                arrow="right"
                className={cnScreenshotsPromo('SliderButton', { type: 'right' })}
                onClick={nextHandler}
                adapt
            />
        </div>
    );
}
