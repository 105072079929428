import * as React from 'react';
import { cn } from '@bem-react/classname';

import { Heading } from '../Heading/Heading';
import { ScreenConstants } from '../../config';

import './GameModesPromo.scss';
import { withSize, IReactAdaptiveProps } from 'react-adaptive';
import { GameModesPromoList } from './GameModesList/GameModesPromo-GameModesList';

export const cnGameModesPromo = cn('GameModesPromo');

interface IGameModesPromoProps extends IReactAdaptiveProps {}

function GameModesPromoComponent({ size }: IGameModesPromoProps) {
    const { width } = size;
    // TODO: подобрать порог
    const sliderPromo = width < ScreenConstants.screenSMPortrait;
    const mobileScreen = width < ScreenConstants.screenXS;
    const slidesToShow = mobileScreen ? 1 : 2;

    return (
        <div className={cnGameModesPromo()}>
            <Heading level="1" enableMargin adapt>
                DIFFERENT GAME MODES
            </Heading>
            <Heading level="5" adapt className={cnGameModesPromo('SubHeader')}>
                There are 3 main game modes that you gradually unlock
            </Heading>

            <GameModesPromoList mode={sliderPromo ? 'slider' : 'plain'} slidesToShow={slidesToShow}/>
        </div>
    );
};

export const GameModesPromo = withSize(GameModesPromoComponent);
