import React from 'react';
import { cn } from '@bem-react/classname';
import { withSize, IReactAdaptiveProps } from 'react-adaptive';
import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image';

import { SocialLinks } from '../SocialLinks/SocialLinks';
import { Logo } from '../Logo/Logo';
import { Icon } from '../Icon/Icon';
import { Button } from '../Button/Button';
import { DownloadSection } from '../DownloadSection/DownloadSection';
import { Heading } from '../Heading/Heading';

import { ScreenConstants, Links, media } from '../../config';

import './MainPromo.scss';

const mainPromoCn = cn('MainPromo');

interface IMainPromoProps extends IReactAdaptiveProps {}

function MainPromoComponent({ size }: IMainPromoProps) {
    const { width } = size;
    const desktopScreen = width > ScreenConstants.screenSM;

    // No way to use constants in query
    // @see README.md "Known issues"
    const images = useStaticQuery(graphql`
        query {
            desktop: file(relativePath: { eq: "MainPromo.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            desktopRetina: file(relativePath: { eq: "MainPromo@2x.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 3840) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            sm: file(relativePath: { eq: "mainPromo-sm.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 768) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            smRetina: file(relativePath: { eq: "mainPromo-sm@2x.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1536) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            xs: file(relativePath: { eq: "mainPromo-xs.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 480) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            xsRetina: file(relativePath: { eq: "mainPromo-xs@2x.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    const bg = [
        images.desktop.childImageSharp.fluid,
        {
            ...images.desktopRetina.childImageSharp.fluid,
            media: media.retina,
        },
        {
            ...images.sm.childImageSharp.fluid,
            media: media.sm,
        },
        {
            ...images.smRetina.childImageSharp.fluid,
            media: media.smRetina,
        },
        {
            ...images.xs.childImageSharp.fluid,
            media: media.xs,
        },
        {
            ...images.xsRetina.childImageSharp.fluid,
            media: media.xsRetina,
        },
    ];

    return (
        <BackgroundImage className={mainPromoCn()} fluid={bg}>
            <SocialLinks headerView />
            <Logo />
            <Heading level="1" className={mainPromoCn('Slogan')} uppercase adapt>
                Lead your team to victory in this unique tactical team shooter!
            </Heading>

            <div className={mainPromoCn('TrailerButton')}>
                <Button size="l" href={Links.trailer} target="_blank" view="green" className={mainPromoCn('WatchButton')} adapt>
                    <Icon adapt type="play" size="l" className={mainPromoCn('PlayIcon')} />
                    WATCH THE TRAILER
                </Button>
            </div>

            {desktopScreen && <DownloadSection />}
        </BackgroundImage>
    );
}

export const MainPromo = withSize(MainPromoComponent);
