import React, { ReactNode } from 'react';
import { cn } from '@bem-react/classname';

import BackgroundImage, { IFluidObject } from 'gatsby-background-image';
import { media } from '../../config';
import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby'

import './GameMode.scss';

const cnGameMode = cn('GameMode');

interface IGameModeProps {
    type: '1' | '2' | '3';
    title: string;
    children: ReactNode;
    adapt?: boolean;
    centered?: boolean;
}

export function GameMode({ type, title, children, adapt, centered }: IGameModeProps) {
     // No way to use constants in query
    // @see README.md "Known issues"
    const images = useStaticQuery(graphql`
        query {
            image1: file(relativePath: { eq: "gamemode1.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 329) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            image1Retina: file(relativePath: { eq: "gamemode1@2x.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 329) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            image2: file(relativePath: { eq: "gamemode2.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 329) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            image2Retina: file(relativePath: { eq: "gamemode2@2x.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 329) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            image3: file(relativePath: { eq: "gamemode3.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 329) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            image3Retina: file(relativePath: { eq: "gamemode3@2x.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 329) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    const getFluid = (n: number) => [
        null, // 0
        [
            images.image1.childImageSharp.fluid,
            {
                ...images.image1Retina.childImageSharp.fluid,
                media: media.retina,
            },
        ],
        [
            images.image2.childImageSharp.fluid,
            {
                ...images.image2Retina.childImageSharp.fluid,
                media: media.retina,
            },
        ],
        [
            images.image3.childImageSharp.fluid,
            {
                ...images.image3Retina.childImageSharp.fluid,
                media: media.retina,
            },
        ],
    ][n];

    return (
        <div className={cnGameMode({ adapt, centered })}>
            <BackgroundImage
                className={cnGameMode('Thumb', { type })}
                fluid={getFluid(Number(type)) as IFluidObject[]}
                />
            <div className={cnGameMode('ThumbTitle')}>{title}</div>
            <div className={cnGameMode('ThumbDescription')}>{children}</div>
        </div>
    );
};
