import * as React from 'react';

import { cnGameModesPromo } from '../GameModesPromo';
import { GameMode } from '../../GameMode/GameMode';
import Slider, { Settings } from 'react-slick';
import { cnSliderDots } from '../../SliderDots/SliderDots';

interface IGameModesPromoList {
    mode?: 'plain' | 'slider';
    slidesToShow?: number;
}

export function GameModesPromoList({ mode = 'plain', slidesToShow = 1 }: IGameModesPromoList) {
    const content = [
        (<GameMode adapt centered type="1" title="King of the Hill" key="game-mode-1">
            Solo<br />
            15 players<br />
            2 exclusive maps
        </GameMode>),
        (<GameMode adapt centered type="2" title="Squad Skirmish" key="game-mode-2">
            Team mode<br />
            5 teams of 3 players<br />
            6 exclusive maps
        </GameMode>),
        (<GameMode adapt centered type="3" title="Team vs. Team" key="game-mode-3">
            Team mode<br />
            2 teams of 5 players<br />
            2 exclusive maps
        </GameMode>)
    ];

    const settings: Settings = {
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow,

        // Does not work properly :( fixed via css
        centerMode: true,
        // https://github.com/akiran/react-slick/issues/648
        centerPadding: '0',
        dots: true,
        dotsClass: cnSliderDots(),
    };

    if (mode === 'slider') {
        return (
            <Slider {...settings}>
                {content.map((item, i) => {
                    return (
                        <div className={cnGameModesPromo('SliderItem')} key={`slider-item-${i}`}>
                            {item}
                        </div>
                    );
                })}
            </Slider>
        )
    }

    return (
        <div className={cnGameModesPromo('GameModesList')}>
            {content}
        </div>
    );
}
