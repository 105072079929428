import * as React from 'react';
import { cn } from '@bem-react/classname';
import { withSize, IReactAdaptiveProps } from 'react-adaptive';
import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image';

import { Heading } from '../Heading/Heading';
import { RewardsPromoSlider } from './Slider/RewardsPromo-Slider';

import { ScreenConstants, media } from '../../config';

import QuotesLogoSVG from '../../images/quotes-logo.inline.svg';

import './RewardsPromo.scss';

export const cnRewardsPromo = cn('RewardsPromo');

interface IRewardsPromoProps extends IReactAdaptiveProps {}

function RewardsPromoComponent({ size }: IRewardsPromoProps) {
    const { width } = size;

    const smallDesktop = width < ScreenConstants.screenLG;
    const padScreen = width < ScreenConstants.screenSM;
    const mobileScreen = width < ScreenConstants.screenSM;

    let iconSize = 100;

    smallDesktop && (iconSize = 85);
    padScreen && (iconSize = 70);
    mobileScreen && (iconSize = 40);

    // No way to use constants in query
    // @see README.md "Known issues"
    const images = useStaticQuery(graphql`
        query {
            desktop: file(relativePath: { eq: "rewards-bg.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            desktopRetina: file(relativePath: { eq: "rewards-bg@2x.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 3840) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            xs: file(relativePath: { eq: "rewards-bg.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 480) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            xsRetina: file(relativePath: { eq: "rewards-bg@2x.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    const bg = [
        images.desktop.childImageSharp.fluid,
        {
            ...images.desktopRetina.childImageSharp.fluid,
            media: media.retina,
        },
        {
            ...images.xs.childImageSharp.fluid,
            media: `(max-width: ${ScreenConstants.screenXS}px)`,
        },
        {
            ...images.xsRetina.childImageSharp.fluid,
            media: media.xsRetina,
        },
    ];

    return (
        <div className={cnRewardsPromo()}>
            <Heading level="1" enableMargin adapt>
                REWARDS
            </Heading>
            <Heading level="5" adapt>
                Play to unlock new heroes, resources, game modes and maps
            </Heading>
            <BackgroundImage className={cnRewardsPromo('Bg')} fluid={bg}></BackgroundImage>
            <div className={cnRewardsPromo('QuotesIcon')}>
                <QuotesLogoSVG width={iconSize} height={iconSize} />
                <RewardsPromoSlider />
            </div>
        </div>
    );
}

export const RewardsPromo = withSize(RewardsPromoComponent);
