import * as React from 'react';
import { cn } from '@bem-react/classname';
import { IReactAdaptiveProps, withSize } from 'react-adaptive';
import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image';

import { Heading } from '../Heading/Heading';
import { DownloadSection } from '../DownloadSection/DownloadSection';
import { ScreenConstants, media } from '../../config';

import './FeaturesPromo.scss';

const cnFeaturesPromo = cn('FeaturesPromo');

interface IFeaturesPromoProps extends IReactAdaptiveProps {}

function FeaturesPromoComponent({ size }: IFeaturesPromoProps) {
    const { width } = size;
    // pad + phone
    const mobileScreen = width <= ScreenConstants.screenXS;
    const padScreen = width <= ScreenConstants.screenSM && !mobileScreen;

    // No way to use constants in query
    // @see README.md "Known issues"
    const images = useStaticQuery(graphql`
        query {
            desktop: file(relativePath: { eq: "featuresPromo.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            desktopRetina: file(relativePath: { eq: "featuresPromo@2x.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 3840) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            xs: file(relativePath: { eq: "FeaturesPromo-xs.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 480) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            xsRetina: file(relativePath: { eq: "FeaturesPromo-xs.jpg" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            bottomImage: file(relativePath: { eq: "featurePromoBottomImage.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1422) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            bottomImageRetina: file(relativePath: { eq: "featurePromoBottomImage@2x.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 2844) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            bottomImageXS: file(relativePath: { eq: "featurePromoBottomImage.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            bottomImageXSRetina: file(relativePath: { eq: "featurePromoBottomImage@2x.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1200) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    const bg = [
        images.desktop.childImageSharp.fluid,
        {
            ...images.desktopRetina.childImageSharp.fluid,
            media: media.retina,
        },
        {
            ...images.xs.childImageSharp.fluid,
            media: media.xs,
        },
        {
            ...images.xsRetina.childImageSharp.fluid,
            media: media.xsRetina,
        },
    ];

    const bgBottomImage = [
        images.bottomImage.childImageSharp.fluid,
        {
            ...images.bottomImageRetina.childImageSharp.fluid,
            media: media.retina,
        },
        {
            ...images.bottomImageXS.childImageSharp.fluid,
            media: media.xs,
        },
        {
            ...images.bottomImageXSRetina.childImageSharp.fluid,
            media: media.xsRetina,
        },
    ];

    return (
        <BackgroundImage className={cnFeaturesPromo()} fluid={bg}>
            {padScreen && <DownloadSection />}
            <Heading level="1" adapt>GAME FEATURES</Heading>
            <Heading level="5" adapt className={cnFeaturesPromo('FeaturesDescription')}>
                Choose from dozens of different heroes with unique play styles and abilities. Unite with friends,
                {!mobileScreen && <br />}
                set a strategy, and be the last team standing when the battle ends.
            </Heading>
            <div className={cnFeaturesPromo('FeaturesList')}>
                <div className={cnFeaturesPromo('Feature')}>
                    <div className={cnFeaturesPromo('FeatureImage', { type: 1 })}></div>
                    <div className={cnFeaturesPromo('FeatureText')}>
                        Designed for team play! Playing with friends is a huge tactical advantage
                    </div>
                </div>
                <div className={cnFeaturesPromo('Feature')}>
                    <div className={cnFeaturesPromo('FeatureImage', { type: 2 })}></div>
                    <div className={cnFeaturesPromo('FeatureText')}>
                        Unlock dozens of heroes with unique play styles
                    </div>
                </div>
                <div className={cnFeaturesPromo('Feature')}>
                    <div className={cnFeaturesPromo('FeatureImage', { type: 3 })}></div>
                    <div className={cnFeaturesPromo('FeatureText')}>
                        Quick fights and auto shooting to make the fun accessible
                    </div>
                </div>
                <div className={cnFeaturesPromo('Feature')}>
                    <div className={cnFeaturesPromo('FeatureImage', { type: 4 })}></div>
                    <div className={cnFeaturesPromo('FeatureText')}>
                        Use special abilities to dominate the arena
                    </div>
                </div>
            </div>
            <div className={cnFeaturesPromo('BottomImageWrapper')}>
                <BackgroundImage
                    className={cnFeaturesPromo('BottomImage')}
                    fluid={bgBottomImage}
                    />
            </div>
        </BackgroundImage>
    );
};

export const FeaturesPromo = withSize(FeaturesPromoComponent);
