import * as React from 'react';
import { cn } from '@bem-react/classname';
import { useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import { media } from '../../config';

import { Heading } from '../Heading/Heading';
import { VarietyPromoSlider } from './Slider/VarietyPromo-Slider';
import { graphql } from 'gatsby';

import './VarietyPromo.scss';

export const cnVarietyPromo = cn('VarietyPromo');

export function VarietyPromo() {
    // No way to use constants in query
    // @see README.md "Known issues"
    const images = useStaticQuery(graphql`
        query {
            desktop: file(relativePath: { eq: "varietyPromo.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            desktopRetina: file(relativePath: { eq: "varietyPromo@2x.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 3840) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    const bg = [
        images.desktop.childImageSharp.fluid,
        {
            ...images.desktopRetina.childImageSharp.fluid,
            media: media.retina,
        }
    ];

    return (
        <BackgroundImage className={cnVarietyPromo()} fluid={bg}>
            <Heading level="1" adapt>VARIOUS HEROES</Heading>
            <Heading level="5" adapt className={cnVarietyPromo('SmallHeading')}>
                There are 21 heroes available at launch and more heroes coming soon!
            </Heading>

            <VarietyPromoSlider />
        </BackgroundImage>
    );
}
