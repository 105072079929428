import * as React from 'react';
import { cn } from '@bem-react/classname';
import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image';

import { ScreenConstants } from '../../config';
import { DownloadBadge } from '../DownloadBadge/DownloadBadge';

import './DownloadSection.scss';

const cnDownloadSection = cn('DownloadSection');

interface IDownloadSectionProps {
    variant?: 'mobile';
}

export function DownloadSection({ variant }: IDownloadSectionProps) {
    const mobileVersion = variant === 'mobile';

    const downloadText = <div className={cnDownloadSection('DownloadText')}>DOWNLOAD FOR FREE NOW!</div>;

    const images = useStaticQuery(graphql`
        query {
            sm: file(relativePath: { eq: "appIconV2.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 512) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            xs: file(relativePath: { eq: "appIconV2.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 480) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);
    const bg = [
        images.sm.childImageSharp.fluid,
        {
            ...images.xs.childImageSharp.fluid,
            media: `(max-width: ${ScreenConstants.screenXS}px)`,
        },
    ];

    return (
        <div className={cnDownloadSection()}>
            {mobileVersion && downloadText}
            <div className={cnDownloadSection('Main')}>
                <BackgroundImage className={cnDownloadSection('AppIcon')} fluid={bg} />
                <div className={cnDownloadSection('DownLoadLinks')}>
                    {!mobileVersion && downloadText}
                    <div className={cnDownloadSection('DownloadBadges')}>
                        <DownloadBadge type="appstore" adapt className={cnDownloadSection('AppstoreBadge')} />
                        <DownloadBadge type="googleplay" adapt />
                    </div>
                </div>
            </div>
        </div>
    );
}
