import * as React from 'react';
import { cn } from '@bem-react/classname';

import './SliderButton.scss';

export const cnSliderButton = cn('SliderButton');

interface ISliderButtonProps {
    className?: string;
    arrow: 'left' | 'right';
    onClick?: () => void;
    adapt?: boolean;
}

export function SliderButton({ arrow, onClick, className, adapt }: ISliderButtonProps) {
    return (
        <div className={cnSliderButton({ arrow, adapt }, [className])} onClick={onClick}>
            <div className={cnSliderButton('Arrow')}></div>
        </div>
    );
};
