import React from 'react';
import { Helmet } from 'react-helmet';
import { cn } from '@bem-react/classname';

import { BrowserConfig, IBrowserConfig } from '../BrowserConfig/BrowserConfig';
import * as Sentry from '@sentry/browser';

// https://www.npmjs.com/package/react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './Layout.scss';

interface ILayoutProps {
    children: React.ReactNode;
}

type IDevice = 'iPad' | 'iPhone' | 'iPod';

interface ILayoutState extends IBrowserConfig {
    device?: IDevice;
}

const cnLayout = cn('Layout');

Sentry.init({ dsn: 'https://90b16ab643cd4d61b3191da0ddd5e485@o21150.ingest.sentry.io/5238717' });

class Layout extends React.Component<ILayoutProps, ILayoutState> {
    constructor(props: ILayoutProps) {
        super(props);

        this.state = {
            platform: undefined,
            inited: false,
        };
    }

    /**
     * Browser environment (such as window) available only in componentDidMount() lifecycle
     * It is used for UA sniff for css and passing down via react context
     */
    public componentDidMount() {
        const platformSniff = /iPad|iPhone|iPod/.exec(navigator.userAgent);
        const device = platformSniff ? platformSniff[0] as IDevice : undefined;
        const platform = device && 'iOS' ;

        this.setState({
            inited: true,
            device,
        });

        // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
        if (platform === 'iOS' && !window.MSStream) {
            this.setState({
                platform,
            })
        }
    }

    public render() {
        const { state, props } = this;
        const { children } = props;
        const { platform, inited, device } = state;

        return (
            <BrowserConfig.Provider value={{ platform, inited }}>
                <Helmet>
                    <link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet" />
                    <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
                    <meta name="apple-itunes-app" content="app-id=1500726361, affiliate-data=at=10lqFH&ct=web"></meta>
                </Helmet>
                <main className={cnLayout('Main', { device })}>{children}</main>

                {/* appsflyer show counter */}
                <img src="https://app.appsflyer.com/id1500726361?pid=site" height="1" width="1" alt="" />
            </BrowserConfig.Provider>
        );
    }
}

export default Layout;
