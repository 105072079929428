import * as React from 'react';
import { cn } from '@bem-react/classname';

import { SocialLinks } from '../SocialLinks/SocialLinks';
import { DownloadBadge } from '../DownloadBadge/DownloadBadge';
import { withSize, IReactAdaptiveProps } from 'react-adaptive';
import { ScreenConstants } from '../../config';

import './Footer.scss';
import { DownloadSection } from '../DownloadSection/DownloadSection';

const cnFooter = cn('Footer');

interface IFooterProps extends IReactAdaptiveProps {}

function FooterComponent({ size }: IFooterProps) {
    const { width } = size;
    // pad + phone
    const touchScreen = width <= ScreenConstants.screenSM;
    const badgeSize = touchScreen ? 'l' : 's';
    const mobileScreen = width <= ScreenConstants.screenXS;

    // Enable mobile footer from tablet portrait because of lack of space
    const rowFooter = width <= ScreenConstants.screenSMPortrait;

    const year = new Date().getFullYear()

    return (
        <footer className={cnFooter()}>
            {mobileScreen && <DownloadSection variant="mobile" />}
            {
                rowFooter ?
                    (<>
                        <SocialLinks
                            policyLinks
                            rowView
                        />

                            <div className={cnFooter('CopyrightBlock')}>
                                <div className={cnFooter('CopyrightText')}>
                                    &copy; 2010-{year} ZeptoLab UK Limited
                                </div>
                            </div>
                    </>) :
                    <SocialLinks
                        policyLinks
                        leftChildren={
                            <div className={cnFooter('DownloadBadges')}>
                                <DownloadBadge type="appstore" size={badgeSize} className={cnFooter('DownloadBadge', { type: 'appstore' })}/>
                                <DownloadBadge type="googleplay" size={badgeSize} className={cnFooter('DownloadBadge', { type: 'googleplay' })}/>
                            </div>
                        }
                        rightChildren={
                            <div className={cnFooter('CopyrightBlock')}>
                                <div className={cnFooter('CopyrightText')}>
                                    &copy; 2010-{year} <br />
                                    ZeptoLab UK Limited
                                </div>
                                <a href="https://www.zeptolab.com/" className={cnFooter('ZeptolabLogo')}>&nbsp;</a>
                            </div>
                        }
                    />
            }
        </footer>
    );
}

export const Footer = withSize(FooterComponent);
