import React from 'react';
import { cn } from '@bem-react/classname';

import './Icon.scss';

interface IIconProps {
    type: 'discord' | 'facebook' | 'help' | 'instagram' | 'twitter' | 'vk' | 'youtube' | 'play' | 'discord-blue';
    href?: string;
    className?: string;
    size?: 'm' | 'l';
    adapt?: boolean;
}

const iconCn = cn('Icon');

export function Icon({ type, href, className, adapt, size = 'm' }: IIconProps) {
    if (!href) {
        return (
            <div className={iconCn({ type, size, adapt }, [className])}>&nbsp;</div>
        );
    }

    return (
        <a className={iconCn({ type, size, adapt }, [className])} href={href}>&nbsp;</a>
    );
}
