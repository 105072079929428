import * as React from 'react';
import { cn } from '@bem-react/classname';

import './Heading.scss';

interface IHeadingProps {
    className?: string;
    /**
     * Supported levels of headings. (from h1 to h6)
     */
    level: '1' | '5';
    children: React.ReactNode;
    enableMargin?: boolean;
    uppercase?: boolean;
    adapt?: boolean;
}

const cnHeading = cn('Heading');

export const Heading: React.FunctionComponent<IHeadingProps> = ({
    className,
    level,
    children,
    enableMargin,
    uppercase,
    adapt,
}) => {
    // Add classes passed with props
    const mixedClass = cnHeading({ level, enableMargin, uppercase, adapt }, [className]);

    return React.createElement(`h${level}`, {
        className: mixedClass,
    }, children);
};
