import React from 'react';

export interface IBrowserConfig {
    platform?: 'iOS';
    inited: boolean;
}

export const BrowserConfig = React.createContext<IBrowserConfig>({
    platform: undefined,
    inited: false,
});
