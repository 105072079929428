import React, { useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import { cnRewardsPromo } from '../RewardsPromo';
import { cnSliderDots } from '../../SliderDots/SliderDots';

import './RewardsPromo-Slider.scss';


export function RewardsPromoSlider() {
    const settings: Settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,

        arrows: false,
        dots: true,
        dotsClass: cnSliderDots(),
        // centerMode: true,
    };

    // // TODO: УБРАТЬ
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    cnSliderDots;

    const sliderRef: React.Ref<Slider> = useRef(null);

    return (
        <div className={cnRewardsPromo('Slider')}>
            <Slider {...settings} ref={sliderRef}>
                <div>
                    “This tactical action is amazing”
                </div>
                <div>
                    “I LOVE THIS GAME! It’s so cool and stressing”
                </div>
                <div>
                    “I like playing with friends, lot’s of fun”
                </div>
            </Slider>
        </div>
    );
};
