export enum ScreenConstants {
    screenXS = 480,
    screenSMPortrait = 720,
    screenSM = 768,
    screenMD = 992,
    screenLG = 1200,
    screenXL = 1600,
    screenXXL = 1920,
    screenXXXL = 2140,
}

export enum Links {
    support = 'https://zepto.helpshift.com/a/bullet-echo/',
    twitter = 'https://twitter.com/bulletecho',
    twitch = 'https://www.twitch.tv/bullet_echo',
    instagram = 'https://www.instagram.com/bullet_echo/',
    vk = 'https://vk.com/becho_community',
    youtube = 'https://www.youtube.com/channel/UCANZz_zbgIo9j3AzrAFciVQ',
    facebook = 'https://www.facebook.com/Bullet-Echo-540217599759495/',
    discord = 'https://discord.gg/u4ApPB7',
    appStore = 'https://go.onelink.me/app/d7ba4aa0',
    playMarket = 'https://go.onelink.me/app/c2282cd1',
    terms = 'https://www.zeptolab.com/terms',
    privacy = 'https://www.zeptolab.com/privacy',
    trailer = 'https://www.youtube.com/watch?v=CLT_3ocCPd4',
}

const dpr = '(-webkit-min-device-pixel-ratio: 2)';
const minRes = '(min-resolution: 192dpi)';
const smMedia = `(max-width: ${ScreenConstants.screenSM}px)`;
const xsMedia = `(max-width: ${ScreenConstants.screenXS}px)`;
export const media = {
    retina: `${dpr}, ${minRes}`,
    sm: smMedia,
    xs: xsMedia,
    smRetina: `${dpr} and ${smMedia}, ${smMedia} and ${minRes}`,
    xsRetina: `${dpr} and ${xsMedia}, ${xsMedia} and ${minRes}`,
};
