import React, { ReactChild } from 'react';
import { cn } from '@bem-react/classname';

import { Icon } from '../Icon/Icon';
import { Button } from '../Button/Button';
import { SocialLinksPolicyLinks } from './PolicyLinks/SocialLinks-PolicyLinks';
import { IReactAdaptiveProps, withSize } from 'react-adaptive';
import { ScreenConstants, Links } from '../../config';

import './SocialLinks.scss';

export const socialLinksCn = cn('SocialLinks');

interface ISocialLinksProps extends IReactAdaptiveProps {
    policyLinks?: boolean;
    rowView?: boolean;
    leftChildren?: ReactChild | ReactChild[];
    rightChildren?: ReactChild | ReactChild[];
    headerView?: boolean;
}

function SocialLinksIconList() {
    return (
        <div className={socialLinksCn('IconList')}>
            <Icon type="facebook" href={Links.facebook} />
            <Icon type="twitter" href={Links.twitter} />
            <Icon type="youtube" href={Links.youtube} />
            <Icon type="instagram" href={Links.instagram} />
            <Icon type="vk" href={Links.vk} />
        </div>
    );
}

function SocialLinksComponent({ policyLinks, leftChildren, rightChildren, size, rowView, headerView }: ISocialLinksProps) {
    const { width } = size;
    const mobileScreen = width <= ScreenConstants.screenXS;

    if (rowView) {
        return (
            <div className={socialLinksCn({ policyLinks, rowView, headerView })}>
                {[
                    <>
                        <div className={socialLinksCn('Support')}>
                            <a href={Links.support} className={socialLinksCn('SupportLink')}>
                                <Icon type="help" />
                                SUPPORT
                            </a>
                        </div>
                        <div className={socialLinksCn('Social')}>
                            <a href={Links.discord} className={socialLinksCn('DiscordLinkXS')}>
                                <Icon type="discord-blue" className={socialLinksCn('BlueDiscordIcon')} />
                                DISCORD
                            </a>
                        </div>
                    </>,
                    policyLinks && <SocialLinksPolicyLinks />,
                    <SocialLinksIconList />,
                ].map((item, i) => {
                    return <div className={socialLinksCn('Row')} key={`social-row-${i}`}>{item}</div>;
                })}
            </div>
        );
    }

    return (
        <div className={socialLinksCn({ policyLinks, headerView })}>
            <div className={socialLinksCn('Left')}>
                <div className={socialLinksCn('Support')}>
                    <a href={Links.support} className={socialLinksCn('SupportLink')}>
                        <Icon type="help" />
                        SUPPORT
                    </a>
                    {policyLinks && <SocialLinksPolicyLinks />}
                </div>
                {leftChildren}
            </div>
            <div className={socialLinksCn('Right')}>
                <div className={socialLinksCn('Social')}>
                    {mobileScreen ? (
                        <>
                            <a href={Links.discord} className={socialLinksCn('DiscordLinkXS')}>
                                <Icon type="discord-blue" className={socialLinksCn('BlueDiscordIcon')} />
                                DISCORD
                            </a>
                        </>
                    ) : (
                        <>
                            <SocialLinksIconList />
                            <Button view="blue" size="s" href={Links.discord} className={socialLinksCn('DiscordLink')}>
                                <Icon type="discord" />
                                DISCORD
                            </Button>
                        </>
                    )}
                </div>
                {rightChildren}
            </div>
        </div>
    );
}

export const SocialLinks = withSize(SocialLinksComponent);
