import * as React from 'react';
import { cn } from '@bem-react/classname';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { media } from '../../config';

import './Logo.scss';

const cnLogo = cn('Logo');

export function Logo() {
    // No way to use constants in query
    // @see README.md "Known issues"
    const images = useStaticQuery(graphql`
        query {
            desktop: file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                    fluid(quality: 95, maxWidth: 413) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            desktopRetina: file(relativePath: { eq: "logo@2x.png" }) {
                childImageSharp {
                    fluid(quality: 95, maxWidth: 413) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            xs: file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                    fluid(quality: 95, maxWidth: 150) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            },
            xsRetina: file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                    fluid(quality: 95, maxWidth: 300) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    const bg = [
        images.desktop.childImageSharp.fluid,
        {
            ...images.desktopRetina.childImageSharp.fluid,
            media: media.retina,
        },
        {
            ...images.xs.childImageSharp.fluid,
            media: media.xs,
        },
        {
            ...images.xsRetina.childImageSharp.fluid,
            media: media.xsRetina,
        },
    ];

    return (
        <BackgroundImage className={cnLogo()} fluid={bg}></BackgroundImage>
    );
};
