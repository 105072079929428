import React, { useContext } from 'react';
import { cn } from '@bem-react/classname';
import { IReactAdaptiveProps, withSize } from 'react-adaptive';
import { DownloadBadge } from '../DownloadBadge/DownloadBadge';

import { ScreenConstants } from '../../config';

import './HeaderDownloadPromo.scss';
import { BrowserConfig } from '../BrowserConfig/BrowserConfig';
import { Helmet } from 'react-helmet';

const cnHeaderDownloadPromo = cn('HeaderDownloadPromo');

interface IHeaderDownloadPromo extends IReactAdaptiveProps {}

function HeaderDownloadPromoComponent({ size }: IHeaderDownloadPromo) {
    const { width } = size;
    const mobileScreen = width <= ScreenConstants.screenXS;
    const browserConfig = useContext(BrowserConfig);

    // Disable for mobile devices and before UA sniff and for iOS
    if (!mobileScreen || !browserConfig.inited || browserConfig.platform === 'iOS') {
        return <></>;
    }

    return (
        <div className={cnHeaderDownloadPromo()}>
            <div className={cnHeaderDownloadPromo('DownloadText')}>
                DOWNLOAD NOW!
            </div>
            <DownloadBadge type="googleplay" size="l"/>
        </div>
    );
};

export const HeaderDownloadPromo = withSize(HeaderDownloadPromoComponent);
